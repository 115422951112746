// **************************************************
// Cart modal
// **************************************************

@import "../../../scss/import";

// Style
.cart-modal {
	z-index: $zindex_modal;
	background-color: rgba($cgp_body, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	@include transition(opacity 0.3s ease);

	&.#{$class_open} {
		opacity: 1;
		pointer-events: auto;
	}

	// **************************************************

	&__content {
		background-color: $cgp_white;
		max-width: 600px;
		width: calc(100% - 24px);
		padding: 30px;
		padding-bottom: 0;
		font-size: 16px;
		max-height: calc(100% - 48px);
		overflow: auto;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 20px 10px;
			padding-bottom: 0;
			font-size: 14px;
			max-height: calc(100% - 24px);
		}
	}

	// **************************************************

	&__header {
		background: url("data:image/svg+xml,#{$modal_cart_icon}") no-repeat center top / 46px auto;
		padding: 36px 0 16px 0;
		text-align: center;

		strong {
			color: $cgp_primary;
			margin-bottom: 3px;
			display: block;
		}

		p {
			font-size: 14px;
		}
	}

	// **************************************************

	&__item {
		border-top: 2px solid $cgp_secondary;
		padding: 16px 0;
		display: grid;
		grid-template-columns: 148px 1fr;
		grid-gap: 40px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 8px 0;
			grid-gap: 20px;
			grid-template-columns: 80px 1fr;
		}

		// **************************************************

		&-price {
			font-size: 20px;
			font-weight: 600;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 18px;
			}
		}

		// **************************************************

		&-info {
			display: flex;
			flex-direction: column;
			align-items: start;
		}

		// **************************************************

		&-info__bottom {
			margin-top: auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding-right: 20px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				padding-right: 0;
			}
		}

		// **************************************************

		&-number {
			display: flex;
			justify-content: center;
			align-items: center;
			color: $cgp_secondary;
			font-size: 20px;
			font-weight: 600;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 18px;
			}

			input {
				color: $cgp_secondary;
				font-size: 20px;
				font-weight: 600;
				outline: none;
				border: none;
				text-align: center;
				width: 28px;
				height: 20px;
				padding: 0;

				// Mobile
				@media #{$breakpoint-xs-only} {
					font-size: 18px;
				}

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				&[type=number] {
					-moz-appearance: textfield;
				}
			}

			.num-up,
			.num-down {
				width: 28px;
				height: 20px;
				cursor: pointer;
				text-align: center;
				@include transition(color 0.3s ease);
				line-height: 1em;

				// Mobile
				@media #{$breakpoint-xs-only} {
					width: 16px;
				}

				&:hover {
					color: $cgp_primary;
				}
			}

			.num-down {
				line-height: 0.8em;
			}
		}
	}

	// **************************************************

	&__remove-item {
		font-family: $font_primary;
		color: $cgp_secondary;
		@include transition(color 0.3s ease);
		margin-top: 20px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 10px;
			margin-bottom: 20px;
		}

		&:hover {
			color: $cgp_primary;
		}
	}

	// **************************************************

	&__back {
		border-top: 2px solid $cgp_secondary;
		border-bottom: 2px solid $cgp_secondary;
		padding: 28px 0;
		text-align: center;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 12px 0;
		}
	}

	// **************************************************

	&__form {
		padding-top: 20px;
	}

	// **************************************************

	.double-inputs {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px;
	}

	// **************************************************

	&__final {
		border-top: 2px solid $cgp_secondary;
		padding-bottom: 40px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-bottom: 30px;
		}

		.btn {
			width: 100%;
		}

		&-price {
			margin-top: 24px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 20px;
			font-weight: 600;
		}
	}

	// **************************************************

	.gift-textarea,
	.second-address {
		display: none;

		&.#{$class_open} {
			display: block;
		}
	}

	// **************************************************

	.word-count {
		display: flex;
		align-items: center;
		font-size: 12px;
	}

	.word-counter {
		margin-right: 4px;
	}
}
