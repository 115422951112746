// ##################################################
// Input
// ##################################################

@import '../../../scss/import';

// Style
.margin {
	display: block;
	width: 100%;

	// ##################################################

	&--200 {
		height: 200px;

		// Desktop small and down
		@media #{$breakpoint-lg-down} {
			height: 160px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			height: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 92px;
		}
	}

	// ##################################################

	&--165 {
		height: 165px;

		// Desktop small and down
		@media #{$breakpoint-lg-down} {
			height: 130px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			height: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 92px;
		}
	}

	// ##################################################

	&--90 {
		height: 90px;

		// Tablet P
		@media #{$breakpoint-sm-down} {
			height: 60px;
		}
	}

	// ##################################################

	&--80 {
		height: 80px;

		// Tablet P
		@media #{$breakpoint-sm-down} {
			height: 60px;
		}
	}

	// ##################################################

	&--60 {
		height: 60px;

		// Tablet P
		@media #{$breakpoint-sm-down} {
			height: 20px;
			height: 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 25px;
		}
	}

	// ##################################################

	&--20 {
		height: 20px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 12px;
		}
	}

	// ##################################################

	&--30 {
		height: 30px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 20px;
		}
	}

	// ##################################################

	&--40 {
		height: 40px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 20px;
		}
	}
}
