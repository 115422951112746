// **************************************************
// Product detail
// **************************************************

@import "../../../scss/import";

// Style
.product-detail {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 110px;
	align-items: start;

	// Tablet L and own
	@media #{$breakpoint-md-down} {
		grid-template-columns: 1fr 1fr;
		grid-gap: 60px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		grid-template-columns: 1fr;
	}

	// **************************************************

	.image-with-caption {
		margin-bottom: 60px;

		&:last-child {
			margin-bottom: 0;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 40px;
		}
	}

	// **************************************************

	&__back-btn {
		text-align: right;
	}

	// **************************************************

	&__subtitle {
		margin-bottom: 12px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 4px;
		}
	}

	// **************************************************

	&__images,
	&__info {
		position: sticky;
		top: 160px;
		@include transition(top 0.3s ease);

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			top: 130px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			top: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			position: relative;
			top: 0;
			order: 2;
		}

		.#{$class_scrolled} & {
			top: 116px;

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				top: 108px;
			}

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				top: 84px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				top: 0;
			}
		}
	}

	// **************************************************

	&__info {
		font-size: 16px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			order: 1;
		}

		// **************************************************

		hr {
			background-color: $cgp_secondary;
			opacity: 0.3;
			height: 0;
			margin: 20px 0;
		}

		// **************************************************

		.editor-text ul li {
			padding-left: 20px;

			&:before {
				content: '•';
				color: $cgp_primary;
				height: auto;
				background-color: transparent;
				top: 0;
			}
		}
	}
}
