//**********************************************************
// Card list
//**********************************************************

@import "../../../scss/import";

// Style
.card-list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 110px 80px;

    // Tablet L and down
    @media #{$breakpoint-md-down} {
		grid-template-columns: 1fr 1fr;
		grid-gap: 110px 80px;
	}

    // Tablet P and down
    @media #{$breakpoint-sm-down} {
		grid-gap: 80px 60px;
	}

    // Mobile
    @media #{$breakpoint-xs-only} {
		grid-template-columns: 1fr;
		grid-gap: 60px;
    }
}
