// **************************************************
// Image with caption
// **************************************************

@import '../../../scss/import';

// Style
.image-with-caption {
	position: relative;

	// ##################################################

	img {
		width: 100%;
	}

	// Figure caption (caption for nested image)
	figcaption,
	.wp-caption {
		text-align: right;
		font-size: 18px;
		padding-top: 16px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 16px;
			padding-top: 12px;
		}
	}

	// ##################################################

	.badge {
		background-color: $cgp_primary;
		color: $cgp_white;
		width: 100px;
		height: 100px;
		border-radius: 100%;
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 16px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		pointer-events: none;
		padding: 10px;
		text-align: center;
	}

	// ##################################################

	figure {
		margin-bottom: 0;
	}
}
