//**********************************************************
// EDITOR TEXT
//**********************************************************

@import "../../../scss/import";

.editor-text {

	.container {
		max-width: 964px;
		width: 100%;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			max-width: 728px;
		}
	}

	// ##################################################
	// Headings

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700;
		margin-bottom: 20px;

		// Margin above the headings
		* + & { margin-top: 30px; }

		// If heading is first child, then no top margin!
		&:first-child { margin-top: 0; }

	}

	// ##################################################

	// H1 Title
	h1 {
		@extend %title_h1;
		font-size: 40px;
	}

	// ##################################################

	// H2 Title
	h2 {
		@extend %title_h2;
	}

	// ##################################################

	// H3 Title
	h3 {
		@extend %title_h3;
	}

	// ##################################################

	// H4 Title
	h4 {
		@extend %title_h4;
	}

	// ##################################################

	// H5 Title
	h5 {
		@extend %title_h5;
		color: $cgp_white;
		margin-bottom: -24px;
	}

	// ##################################################

	// H6 Title
	h6 {
		@extend %title_h6;
	}

	// ##################################################
	// General text elements

	p,
	ul,
	ol,
	img,
	figure,
	table {
		margin-top: 1.5em;
		margin-bottom: 1.5em;

		&:first-child { margin-top: 0; }

		&:last-child { margin-bottom: 0; }

		a { text-decoration: underline; }

	}

	// ##################################################
	// Images

	// img {}

	// Figure (nested image for caption)
	figure {
		img { margin: 0; }
	}

	// Figure caption (caption for nested image)
	// figcaption,
	// .wp-caption { }

	// ##################################################
	// List styles

	ul,
	ol {
		list-style: none;

		li {
			position: relative;
			padding-left: 1.75em;
			margin-bottom: 12px;
		}

	}

	ul {

		li:before {
			width: 14px;
			height: 2px;
			background-color: $cgp_primary;
			color: inherit;
			top: 0.5em;
			@extend %content;
		}

	}

	ol {
		counter-reset: ol_text_counter;

		li {
			counter-increment: ol_text_counter;

			&:before {
				content: counter(ol_text_counter) '.';
				font-size: inherit;
				position: absolute;
				left: 0;
				@extend %inherit_textstyles;
			}

		}

	}

	// ##################################################
	// Tables

	table {
		border: 1px solid;
		width: 100%;

		tr { border-bottom: 1px solid; }

		th,
		td {
			padding: 0.5em;

			&:not(:first-child) { border-left: 1px solid; }

		}

	}

	// ##################################################
	// Blockquote

	blockquote {

		p { margin: 0 !important; }

	}

	// ##################################################

	p {
		line-height: 1.5em;
	}
}
