// ##################################################
// Cookie banner
// ##################################################

@import '../../../scss/import';

// Style
.cookie-banner {
	box-shadow: $box_shadow_secondary;
	z-index: $zindex_cookie_notice;
	color: $cgp_secondary;
	background-color: $cgp_body;
	border-top: 1px solid $cgp_secondary;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	padding: 40px 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s ease, visibility 0s linear 0.5s;

	// If Cookie is not set
	&.cookie-not-set {
		opacity: 1;
		pointer-events: all;
		visibility: visible;
		transition: opacity 0.5s ease, visibility 0s linear;
	}

	// Mobile
	@media #{$breakpoint-xs-only} { padding: 20px 0; }

	// ##################################################

	// Width
	.cookie-width {
		display: flex;
		justify-content: space-between;
		align-items: center;

		// Mobile
		@media #{$breakpoint-xs-only} { flex-direction: column; }

	}

	// ##################################################

	// Text
	// &__text {}

	// ##################################################

	// Button
	&__button {
		margin-left: 50px;

		// Tablet P and up
		@media #{$breakpoint-sm-up} {
			white-space: nowrap;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-left: 0;
			margin-top: 20px;
			width: 100%;
			text-align: center;
		}
	}

	// ##################################################

	.btn--cookie {
		white-space: nowrap;

		// Mobile
		@media #{$breakpoint-xs-only} { width: 100%; }

	}

	&__link {
		text-decoration: underline;
		font-size: 15px;
		margin-left: 12px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			text-align: center;
			display: inline-block;
			margin-top: 12px;
			margin-left: 0;
		}
	}

}
