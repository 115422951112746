//*******************************************************************************
// 1.0 - GENERAL
//*******************************************************************************

html,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

// Text selection styling
::selection {
	background-color: $cgp_primary;
	color: $cgp_white;
}

// Modal window - prevent scrolling
body {
	background-color: $cgp_body;
	color: $cgp_secondary;
	font-family: $font_primary;
	position: relative;
	height: auto;
	min-height: 100%;
	width: 100%;
	@extend %page_text;

	/* stylelint-disable-next-line */
	&.#{$class_open_menu},
	&.noscroll {
		overflow: hidden;
	}
}

// Prevents text selection styling
.noselect,
.noselect * { user-select: none; }

ul,
ol {
	list-style: none;
	margin: 0;
	padding: 0;
}

.scroll-to {
    padding-top: 120px;
    margin-top: -120px;
}

//*******************************************************************************
// 2.0 - GENERAL : Text styles
//*******************************************************************************

// Everything inside will inherit text style from parent
.text,
.title,
.title-main {

	* { @extend %inherit_textstyles; }

}

// Paragraphs
p { margin: 0; }

// Links
a {
	color: inherit;
	text-decoration: none;
	@include transition(color 0.3s ease, border 0.3s ease);
}

// Headings
@for $i from 1 through 6 {
	/* stylelint-disable-next-line */
	h#{$i} {
		font-weight: inherit;
		font-size: inherit;
		margin: 0;
	}
}

:focus { outline: none; }



//*******************************************************************************
// 3.0 - GENERAL : Images
//*******************************************************************************

// General image
img {
	margin: 0;
	max-height: 100%;
	max-width: 100%;
	height: auto;
	width: auto;
}

// Figure (nested image for caption)
figure {

	img { margin: 0; }

}

// Figure caption (caption for nested image)
figcaption,
.wp-caption {
	font-size: 13px;
	padding-top: 12px;
	line-height: 1.2;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 12px;
		padding-top: 10px;
	}

}


//*******************************************************************************
// 4.0 - GENERAL : Section
//*******************************************************************************

.sc-general {
	position: relative;
}



//*******************************************************************************
// 5.0 - GENERAL : Slider
//*******************************************************************************

// Hide all slides expect first one till slider loading
.swiper-container:not(.swiper-container-initialized) {

	.swiper-pagination,
	.swiper-slide:not(:first-of-type) { display: none !important; }

}

// Reset the default arrows
.swiper-button-prev:after,
.swiper-button-next:after { font-size: 0 !important; }



//*******************************************************************************
// 6.0 GENERAL : Form
//*******************************************************************************

form select::-ms-expand { display: none; }

input,
select,
textarea {
	box-shadow: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

// Ipad and mobile devices
input,
textarea {
	-webkit-appearance: none;
	border-radius: 0;
}

// Remove default red border for HTML5 validation
input:invalid { outline: none; }

// Removes "clear field" X buttons in IE
textarea::-ms-clear,
input::-ms-clear { display: none; }

button { @extend %reset_button; }



//*******************************************************************************
// X.X MISC : Lazy loaded images
//*******************************************************************************

// Lazy loaded image animation
img[data-src] {
	background: radial-gradient(circle, #eee 0%, #ddd 100%);
	overflow: hidden; // For broken image styling
	position: relative; // For broken image styling

	&:after {
		@extend %content;
		left: 50%;
		top: 50%;
		color: white;
		font-size: 1vw;
		margin: -0.5em -0.5em;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		text-indent: -9999em;
		animation: img_loader 1.3s infinite linear;
	}

}

@keyframes img_loader {

	0%,
	100% { box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
	12.5% { box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
	25% { box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
	37.5% { box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 0, 2em 2em 0 0.2em, 0 3em 0 0, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
	50% { box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0 0 -1em, -2em -2em 0 -1em; }
	62.5% { box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
	75% { box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0 0 0.2em, -2em -2em 0 0; }
	87.5% { box-shadow: 0 -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0 0 0, -2em -2em 0 0.2em; }

}
