// **************************************************
// Site Header
// **************************************************

@import "../../../scss/import";

// Style
.header-main {
	background-color: $cgp_body;
	position: fixed;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 32px 0 24px 0;
	transition: 0.2s;
	z-index: 10;
	@include transition(padding 0.3s ease);

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		padding: 16px 0;
	}

	&:after {
		background-color: $cgp_border;
		@extend %content;
		top: auto;
		bottom: 0;
		height: 2px;
		max-width: 1288px;
		width: 100%;
		right: 0;
		margin: auto;
	}

	&:before {
		@extend %content;
		top: auto;
		bottom: -24px;
		height: 29px;
		max-width: 1288px;
		width: 100%;
		right: 0;
		margin: auto;
		background-image: linear-gradient(to top, rgba(21, 21, 21, 0) 0%, rgba(21, 21, 21, 0.08) 8%, #151515 100%);
	}

	.#{$class_scrolled} & {
		padding: 16px 0;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 8px 0;
		}
	}

	// ##################################################

	// Inner width
	&__inner {
		max-width: $header_width;
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		// Desktop small and down
		@media #{$breakpoint-lg-down} {
			max-width: 960px;
			padding: 0 10px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			max-width: 728px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: calc(100% - 32px);
		}
	}

	// ##################################################

	&__logo {
		max-width: 460px;
		transition: opacity 0.3s ease;
		display: flex;
		align-items: center;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			max-width: 100%;
		}

		&:hover {
			opacity: 0.6;
		}

		&-text {
			width: 289px;
			margin-left: 40px;
			@include transition(width 0.3s ease, margin-left 0.3s ease);

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				width: 240px;
				margin-left: 20px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 100px;
				margin-left: 16px;
			}

			.#{$class_scrolled} & {
				margin-left: 20px;
				width: 240px;

				// Mobile
				@media #{$breakpoint-xs-only} {
					width: 100px;
					margin-left: 16px;
				}
			}

			&.desktop {
				display: block;

				// Mobile
				@media #{$breakpoint-xs-only} {
					display: none;
				}
			}
			&.mobile {
				display: none;

				// Mobile
				@media #{$breakpoint-xs-only} {
					display: block;
				}
			}
		}

		&-icon {
			width: 120px;
			@include transition(width 0.3s ease);

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				width: 80px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 56px;
			}

			.#{$class_scrolled} & {
				width: 80px;

				// Mobile
				@media #{$breakpoint-xs-only} {
					width: 56px;
				}
			}
		}
	}

	// ##################################################

	&__cart {
		text-align: center;
		cursor: pointer;

		&:hover {
			.header-main__cart-text {
				color: $cgp_primary;
			}

			.header-main__cart-icon {
				background-image: url("data:image/svg+xml,#{$cart_icon--red}");
			}
		}

		&-icon {
			background: url("data:image/svg+xml,#{$cart_icon}") no-repeat center bottom / 34px auto;
			width: 100%;
			height: 23px;
			font-size: 18px;
			font-weight: 600;
			line-height: normal;
			padding-bottom: 24px;
			transition: background-image 0.3s ease;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 16px;
				background-size: 36px auto;
				padding-bottom: 20px;
			}
		}

		&-text {
			color: $cgp_white;
			font-size: 14px;
			font-weight: 600;
			transition: color 0.3s ease;
			margin-top: 4px;

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 12px;
			}
		}
	}

	// ##################################################

	&__contact {
		color: $cgp_white;
		margin-left: auto;
		font-size: 14px;
		display: flex;
		align-items: center;
		margin-right: 40px;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			margin-right: 20px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}

		a:hover {
			color: $cgp_primary;
		}

		&-item {
			display: flex;
			align-items: center;
			margin-right: 40px;
			font-weight: 600;

			// Tablet L and down
			@media #{$breakpoint-md-down} {
				margin-right: 20px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&-icon {
			margin-right: 14px;
		}
	}
}
