// **************************************************
// App landing
// **************************************************

@import "../../../scss/import";

// Style
.header-app {
	padding: 40px 0 32px;

	// **************************************************

	&__inner {
		display: grid;
		grid-template-columns: 1fr 125px 1fr;
		grid-gap: 28px;
		align-items: center;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-template-columns: 1fr 92px 1fr;
		}
	}

	// **************************************************

	.line {
		background-color: $cgp_secondary;
		height: 1px;
		width: 100%;
	}
}

// **************************************************

.container--xs {
	max-width: 568px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: calc(100% - 32px);
	}
}

// **************************************************

.container--s {
	max-width: 720px;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		max-width: 568px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		max-width: calc(100% - 32px);
	}
}

// **************************************************

.app-page {
	.top-text {
		text-align: center;
	}

	// **************************************************

	.choose-language {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 32px;
		margin: 80px 0;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-gap: 24px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 20px;
			grid-template-columns: 1fr;
			margin: 60px 0;
		}

		// **************************************************

		&__block {
			padding: 92px 0 85px;
			border-radius: 11px;
			border: 1px solid rgba($cgp_secondary, 0.2);
			transition: border-color 0.3s ease;
			text-align: center;
			color: $cgp_white;
			font-family: $font_dm_serif;
			font-size: 64px;
			font-weight: 400;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			// Tablet P and down
			@media #{$breakpoint-sm-down} {
				font-size: 48px;
				padding: 92px 0 120px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				font-size: 38px;
				padding: 80px 0 92px;
			}

			&:hover {
				border-color: $cgp_secondary;
			}

			// **************************************************

			img {
				width: 132px;
				margin-bottom: 20px;
			}
		}
	}

	// **************************************************

	.categories-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 32px;
		margin-top: 60px;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			grid-gap: 32px 24px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 20px;
		}

		@media only screen and (max-width: 375px) {
			grid-template-columns: 1fr;
		}

		// **************************************************

		.category {
			width: 100%;
			padding-top: 100%;
			border-radius: 12px;
			border: 1px solid $cgp_secondary;
			position: relative;
			color: $cgp_white;
			font-family: $font_dm_serif;
			font-weight: 400;

			@media only screen and (max-width: 375px) {
				padding-top: 0;
			}

			&.green {
				border-color: #1ec08c
			}
			&.orange {
				border-color: #f28e1e;
			}

			// **************************************************

			&__top {
				position: absolute;
				top: 0;
				left: 2px;
				right: 16px;
				font-size: 12px;
				text-transform: uppercase;
				display: grid;
				grid-template-columns: 64px 1fr;
				grid-gap: 4px;
				align-items: center;

				// Mobile
				@media #{$breakpoint-xs-only} {
					grid-template-columns: 48px 1fr;
					font-size: 11px;
					right: 12px;
				}

				@media only screen and (max-width: 375px) {
					position: relative;
				}

				img {
					width: 100%;
				}
			}

			// **************************************************

			&__title {
				position: absolute;
				bottom: 20px;
				left: 16px;
				right: 24px;
				font-size: 32px;
				line-height: 1.17;

				// Tablet P and down
				@media #{$breakpoint-sm-down} {
					font-size: 24px;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					font-size: 18px;
					bottom: 12px;
					left: 12px;
					right: 12px;
				}

				@media only screen and (max-width: 375px) {
					position: relative;
					margin-top: 32px;
				}
			}
		}
	}

	// **************************************************

	.hero-image {
		margin-bottom: 36px;

		img {
			width: 100%;
		}
	}

	// **************************************************

	.content-text {
		h1,h2,h3,h4,h5,h6 {
			color: $cgp_white;
			font-family: $font_dm_serif;
			font-weight: 400;
		}
		h1 {
			font-size: 32px;
		}
	}

	// **************************************************

	.player {
		text-align: center;
		margin: 64px 0 52px;

		&__btn {
			width: 268px;
			height: 48px;
			border-radius: 40px;
			border: 1px solid $cgp_secondary;
			margin: 0 auto;
			background: url("data:image/svg+xml,#{$pause_icon}") no-repeat center / 18px auto;

			&.is-paused {
				background: url("data:image/svg+xml,#{$play_icon}") no-repeat center / 18px auto;
			}
		}

		// **************************************************

		&__track {
			margin-top: 36px;
			width: 100%;
			height: 9px;
			border-radius: 8px;
			background-color: rgba(#a39686, 0.1);
			position: relative;
		}

		// **************************************************

		&__line {
			background-color: $cgp_primary;
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			border-radius: 8px;

			&:after {
				content: '';
				position: absolute;
				height: 26px;
				width: 26px;
				border-radius: 100%;
				background-color: $cgp_primary;
				right: -13px;
				top: -9px;
			}
		}

		// **************************************************

		&__time {
			margin-top: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	// **************************************************

	.app-video {
		width: 100%;
		padding-top: 56.25%;
		position: relative;
		margin-top: 32px;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

// **************************************************

.back-btn {
	color: $cgp_white;
	background-color: $cgp_body;
	font-size: 16px;
	font-weight: 700;
	padding-left: 28px;
	background: url("data:image/svg+xml,#{$back_btn_arrow}") no-repeat left -20px center / auto;

	&__wrap {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr;
		grid-gap: 10px;
	}
}

// **************************************************

.footer-app {
	margin: 52px 0 80px;

	// **************************************************

	.line {
		height: 1px;
		background-color: $cgp_secondary;
		width: 100%;
	}

	// **************************************************

	&__inner {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr auto;
		grid-gap: 10px;
	}

	// **************************************************

	&__languages {
		display: grid;
		grid-template-columns: repeat(2, 24px);
		grid-gap: 4px;

		a {
			opacity: 0.2;

			&:hover {
				opacity: 1;
			}
		}
	}
}
