//**********************************************************
// INPUT FIELD
//**********************************************************

@import "../../../scss/import";

.input-field {
	margin-top: 24px;
	width: 100%;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-top: 16px;
	}

	// ##################################################

	&__label {
		display: block;
		color: inherit;
		font-size: 18px;
		margin-bottom: 8px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 14px;
			margin-bottom: 4px;
		}
	}

	// ##################################################

	&__input {
		background-color: $cgp_white;
		color: $cgp_secondary;
		border: 1px solid $cgp_secondary;
		width: 100%;
		height: 42px;
		outline: none;
		font-weight: 500;
		padding: 0 16px;
		@include transition(border 0.3s ease);

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 40px;
			padding: 0 8px;
		}
	}

	// ##################################################

	textarea.input-field__input {
		border: 2px solid $cgp_secondary;
		min-height: 100px;
		box-sizing: border-box;
		padding-top: 12px;
	}

	// ##################################################

	&__message {
		display: none;
		width: 100%;
		position: absolute;
		color: $cgp_primary;
		left: 0;
		top: calc(100% + 7px);
	}

	// ##################################################

	&.is-failed {

		&::before,
		.input-field__input {
			border-color: $cgp_primary;
		}

		.input-field__message {
			display: block;
		}
	}
}
