// **************************************************
// Title
// **************************************************

@import "../../../scss/import";

// Style
.title {
	font-weight: 700;
	position: relative;

	&.with-shadow {
		padding-left: 32px;

		// Tablet PL and down
		@media #{$breakpoint-md-down} {
			padding-left: 16px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-left: 0;
		}
	}

	// H1 Title
	&--h1 {
		@extend %title_h1;
	}

	// **************************************************

	// H2 Title
	&--h2 {
		@extend %title_h2;
	}

	// **************************************************

	// H3 Title
	&--h3 {
		@extend %title_h3;
	}

	// **************************************************

	// H4 Title
	&--h4 {
		@extend %title_h4;
	}

	// **************************************************

	// H5 Title
	&--h5 {
		@extend %title_h5;
		font-weight: 500;
	}

	// **************************************************

	// H6 Title
	&--h6 {
		@extend %title_h6;
	}

	// **************************************************

	&__shadow {
		color: $cgp_secondary;
		position: absolute;
		font-size: 130px;
		opacity: 0.05;
		left: 0;
		top: -74px;
		pointer-events: none;
		z-index: -1;

		// Tablet L and down
		@media #{$breakpoint-md-down} {
			font-size: 80px;
			top: -32px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 60px;
			top: -32px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			display: none;
		}
	}
}
