// ##################################################
// Button
// ##################################################

@import "../../../scss/import";

// Style
.btn {
	color: $cgp_white;
	background-color: $cgp_primary;
	padding: 17px 30px;
	cursor: pointer;
	font-size: 18px;
	font-weight: 700;
	transition: background-color 0.25s ease-in;
	display: inline-grid;
	align-items: center;
	justify-content: center;
	grid-auto-flow: column;
	grid-gap: 22px;
	text-transform: uppercase;
	position: relative;

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 14px 24px;
		font-size: 16px;
	}

	// Hover/Focus
	&:hover,
	&:focus {
		background-color: darken($cgp_primary, 20%);
	}

	// ##################################################

	&--secondary {
		color: $cgp_primary;
		background-color: transparent;
		font-size: 16px;
		font-weight: 600;
		padding: 0;
		padding-left: 24px;

		// Hover/Focus
		&:hover,
		&:focus {
			background-color: transparent;
			text-decoration: underline;

			&:after {
				height: 14px;
			}
		}

		&:before,
		&:after {
			@extend %content;
			width: 14px;
			height: 2px;
			top: 0;
			bottom: 0;
			margin: auto;
			background-color: $cgp_secondary;
			@include transition(width 0.3s ease, left 0.3s ease);
		}

		&:after {
			width: 2px;
			height: 0;
			left: 6px;
			@include transition(height 0.3s ease);
		}
	}

	// ##################################################

	&--small {
		font-size: 16px;
		font-weight: 600;
		padding: 6px 20px;
	}

	// ##################################################

	&.back-link {
		padding: 4px 0 4px 48px;
		background: url("data:image/svg+xml,#{$chevron_left}") no-repeat left 12px center / 12px auto;
		@include transition(padding 0.3s ease, background-position 0.3s ease);

		&:hover {
			background-position: left;

			&:before {
				left: 2px;
				width: 38px;
			}
		}

		&:before {
			width: 26px;
			left: 14px;
		}

		&:after {
			display: none;
		}
	}

	// ##################################################

	&.add-basket {
		background: $cgp_primary url("data:image/svg+xml,#{$add_to_cart_icon}") no-repeat left 20px center / 26px auto;
		padding-left: 68px;

		// Hover/Focus
		&:hover,
		&:focus {
			background-color: darken($cgp_primary, 20%);
		}
	}
}
