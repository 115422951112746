// **************************************************
// Checkbox
// **************************************************

@import '../../../scss/import';

// Style
.checkbox__label {
	display: inline-block;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	margin: 0;
	margin-top: 24px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-top: 16px;
	}

	// **************************************************

	.checkmark {
		border: 2px solid $cgp_secondary;
		background-color: $cgp_white;
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;

		&:after {
			@extend %content;
			top: 1px;
			background: url("data:image/svg+xml,#{$checkmark}") no-repeat center / 10px auto;
			opacity: 0;
			@include transition(opacity 0.3s ease);
		}
	}

	// **************************************************

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked~.checkmark {
			&:after {
				opacity: 1;
			}
		}
	}
}
