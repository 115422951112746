// **************************************************
// Success modal
// **************************************************

@import "../../../scss/import";

// Style
.success-modal {
	z-index: $zindex_modal;
	background-color: rgba($cgp_body, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	@include transition(opacity 0.3s ease);

	&.#{$class_open} {
		opacity: 1;
		pointer-events: auto;
	}

	// **************************************************

	&__content {
		background-color: $cgp_white;
		max-width: 600px;
		width: calc(100% - 24px);
		padding: 30px;
		padding-bottom: 40px;
		font-size: 14px;
		line-height: 1.5em;
		max-height: calc(100% - 48px);
		overflow: auto;

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 20px 10px;
			padding-bottom: 30px;
			font-size: 14px;
			max-height: calc(100% - 24px);
		}
	}

	// **************************************************

	&__header {
		background: url("data:image/svg+xml,#{$success}") no-repeat center top / 33px auto;
		padding: 48px 0 16px 0;
		text-align: center;
		border-bottom: 1px solid $cgp_secondary;

		p {
			margin-top: 16px;
		}
	}

	// **************************************************

	&__final {
		text-align: center;
	}

	// **************************************************

	&__info {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 16px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-template-columns: 1fr;
		}
	}

	&__time {
		text-align: right;

		// Mobile
		@media #{$breakpoint-xs-only} {
			text-align: left;
			margin-top: 12px;
		}
	}
}
