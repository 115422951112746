// **************************************************
// Body text
// **************************************************

@import '../../../scss/import';

// Style
.body-text {
	// **************************************************

	// Body text 14px
	&--14 {
		@extend %fontsize14;
	}

	// **************************************************

	// Body text 16px
	&--16 {
		@extend %fontsize16;
	}

	// **************************************************

	// Body text 18px
	&--18 {
		@extend %fontsize18;
	}

	// **************************************************

	// Body text link
	a {
		text-decoration: underline;
	}
}
