// **************************************************
// Radio button
// **************************************************

@import '../../../scss/import';

// Style
.radio-label {
	display: inline-block;
	position: relative;
	padding-left: 33px;
	cursor: pointer;
	margin: 0;
	margin-top: 24px;

	// Mobile
	@media #{$breakpoint-xs-only} {
		margin-top: 16px;
	}

	// **************************************************

	.checkmark {
		border: 2px solid $cgp_secondary;
		background-color: $cgp_white;
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		border-radius: 100%;

		&:after {
			@extend %content;
			background-color: $cgp_primary;
			top: 3px;
			left: 3px;
			opacity: 0;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			@include transition(opacity 0.3s ease);
		}
	}

	// **************************************************

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked~.checkmark {
			&:after {
				opacity: 1;
			}
		}
	}

	// **************************************************

	.radio-text {
		span {
			display: block;
			font-size: 12px;
			font-weight: 500;
		}
	}
}
